import {useState, useCallback} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import config from './config/config.js';
import Alert from 'react-bootstrap/Alert';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App(props) {
  const {useGoogleReCaptcha} = props;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [cardType, setCardType] = useState('');
  const [cardCVI, setCardCVI] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [freeGuide, setFreeGuide] = useState('yes');
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [validated, setValidated] = useState(false);

  const handleReCaptchaVerify = useCallback(async (e) => {
    console.log({where: '39', e});
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    console.log({where: '44'});
    const token = await executeRecaptcha('membership');
    console.log({where: '45', token});
    // Do whatever you want with the token
    console.log({where: '47', token});

    e.preventDefault();
    setErrorMessage(null);
    setShow(false);
    const formBody = {
      firstName,
      lastName,
      email,
      phone,
      password,
      freeGuide,
      captchaToken: token,
      address,
      city,
      state,
      zip,
      cardCVI,
      cardMonth,
      cardNumber,
      cardType,
      cardYear
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        "Accept": "*/*",
        "Content-Type": "application/json;charset=UTF-8"
      },
      body: JSON.stringify(formBody)
    };
    console.log(formBody)
    const response = await fetch(`${config.apiGateway}/membershipsignup`, requestOptions);
    const data = await response.json();
    if(data.success){
      window.location.href = `https://toolchest.rentingauthority.com/?user=${email}&pass=${password}`;
      return;
    }
    console.log(data)
    setErrorMessage({
      message: 'reCaptcha error. Reload page and try again.',
      ...data
    });
    setShow(true);
  }, [address, cardCVI, cardMonth, cardNumber, cardType, cardYear, city, email, executeRecaptcha, firstName, freeGuide, lastName, password, phone, state, zip]);

  const handleSubmit = (e) => {
    console.log({where: '103'});
    const form = e.currentTarget;
    console.log({where: '49', form});
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setValidated(true);
    e.preventDefault();
    handleReCaptchaVerify(e);
  };

  const handleChange = e => {
    e.persist();
    console.log(e.target.value);

    setFreeGuide(e.target.value);
  };

  return (
    <Container>
         <p>
        After 20 wonderful years of serving you, we wanted to share some important news. As of June 23, 2024, we will no longer be processing reports.
      </p>
      <p>
        We deeply appreciate the trust and loyalty you have placed in us over the years. Your support has been integral to our success and growth, and we are truly grateful for the opportunity to have been a part of your journey.
      </p>
<p>
  Thank you for your understanding and continued support.
</p>
<p>
  Warm regards,
</p>
    </Container>
    );
}

export default App;
